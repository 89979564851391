<template>
  <div>
    <!-- Title -->
    <div class="sela-form-title mb-5">{{ t('register') }}</div>

    <!-- Phone -->
    <AuthPhoneField
      id="register-phone-input"
      v-model="form.phone"
      error="register-phone"
    />

    <!-- Email -->
    <AuthTextField
      v-model="form.email"
      :label="t('email')"
      placeholder="example@email.com"
      type="email"
      error="register-email"
    />

    <!-- Password -->
    <AuthTextField
      v-model="form.password"
      :label="t('password')"
      placeholder="********"
      type="password"
      error="register-password"
    />

    <!-- Password Confirmation -->
    <AuthTextField
      v-model="form.passwordConfirmation"
      :label="t('password_confirmation')"
      placeholder="********"
      type="password"
      error="register-password_confirmation"
    />

    <!-- Privecy Policy -->
    <SelaCheckbox v-model="form.terms" :label="false" class="mt-5">
      <div class="mr-2">
        {{ t('i_ve_read') }}
        <SelaLink
          :with-branch-id-query="false"
          to="/terms"
          target="_blank"
          class="text-sm text-nav_color font-medium hover:underline"
          :aria-label="t('aria_page_label', { page: t('terms_of_use') })"
        >
          {{ t('terms_of_use') }}
        </SelaLink>
        {{ t('and_agree_it') }}
      </div>
    </SelaCheckbox>

    <!-- Submit -->
    <SelaResponseError
      class="my-2"
      api-key="register"
      :except="['register-email', 'register-phone', 'register-password']"
    />
    <SelaSubmitButton
      class="my-2"
      :loading="form.loading"
      :enable-click-disable-mode="true"
      :disabled="!form.terms"
      @submit="registerHandler"
    />
  </div>
</template>

<script setup lang="ts">
definePageMeta({
  auth: {
    unauthenticatedOnly: true,
    navigateAuthenticatedTo: '/'
  }
})

const { form, registerHandler } = useRegister()
const { url } = useDomainHost()
const { t } = useI18n()

useServerSeoMeta(
  useOgMeta(
    url + '/auth/register',
    t('register'),
    t('sila_description'),
    url + '/images/sela.webp'
  )
)

useHead({
  title: t('register'),
  link: [useSeoCanonical(url + '/auth/register')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('register'), url + '/auth/register')
    ])
  ]
})
</script>
